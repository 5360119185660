import React from "react";
import { IconElement } from "./icon.element";
import { classNames } from "../../helpers/class-names.helper";
import { fireClickEvents } from "../../events/events.helper";

function createTrackingDataAttributes(tracking) {
  if (!(tracking && typeof tracking === "object")) {
    return {};
  }

  const base = {};
  const { actionLabel, actionValue, actionPosition } = tracking;

  if (actionLabel) {
    base["data-tracking-action-label"] = actionLabel;
  }

  if (actionValue) {
    base["data-tracking-action-value"] = actionValue;
  }

  if (actionPosition) {
    base["data-tracking-action-position"] = actionPosition;
  }

  return base;
}

export function ButtonElement({
  text,
  link,
  linkTarget = null,
  icon = null,
  external = false,
  isPrimary = false,
  isSecondary = false,
  isInverted = false,
  isCharmless = false,
  size = "md",
  events = null,
  tracking = null,
  color,
}) {
  const baseClassNames =
    "whitespace-nowrap inline-flex items-center justify-center border border-transparent font-medium xx-rounded-md focus:outline-none focus:shadow-outline transition duration-150 ease-in-out cursor-pointer";

  const sizeClassNames = {
    xl: "text-lg px-8 py-4 leading-6 md:py-5 md:text-xl md:px-10",
    lg: "text-base px-3 py-3 leading-6  md:py-3 md:text-lg md:px-4",
    md: "text-base px-5 py-3 leading-6",
    sm: "text-sm px-4 py-2 leading-4",
    xs: "text-xs px-3 py-1.5 leading-3",
  };

  const sizeClassName = sizeClassNames?.[size];

  const colorClassNames = [];

  const trackingAttributes = createTrackingDataAttributes(tracking);

  if (isPrimary) {
    colorClassNames.push(
      !isInverted
        ? `text-white ${color.bg[500]} hover:${color.bg[600]}`
        : `${color.text[600]} bg-white hover:${color.text[500]} hover:${color.bg[50]}`
    );
  }

  if (isSecondary) {
    colorClassNames.push(
      !isInverted
        ? `${color.text[700]} ${color.text[700]} ${color.bg[100]} hover:${color.text[600]} hover:${color.bg[50]} focus:outline-none focus:${color.border[300]}`
        : `${color.text[300]} ${color.bg[900]} hover:text-indigo-50 hover:${color.bg[900]}`
    );
  }

  if (isCharmless) {
    colorClassNames.push(
      !isInverted
        ? "text-gray-500 hover:text-gray-600 focus:outline-none"
        : "text-gray-600 hover:text-gray-500 focus:outline-none"
    );
  }

  const classNamesList = [
    baseClassNames,
    sizeClassName,
    colorClassNames.join(" "),
  ].join(" ");

  return (
    <a
      href={link}
      target={linkTarget}
      rel={linkTarget ? "noopener noreferrer" : null}
      className={classNamesList}
      onClick={() => fireClickEvents(events)}
      {...trackingAttributes}
    >
      {icon && (
        <span
          className={classNames([
            size === "xs" && "mr-3",
            size === "sm" && "mr-3",
            size === "md" && "mr-3",
            size === "lg" && "mr-3",
            size === "xl" && "mr-4",
          ])}
        >
          <IconElement icon={icon} />
        </span>
      )}
      {text}
      {external && (
        <svg
          className="-mr-1 ml-3 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
          <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
        </svg>
      )}
    </a>
  );
}
