import React from "react";
import { FeatureSubtitleElement } from "./elements/feature.subtitle.element";
import { FeatureTitleElement } from "./elements/feature.title.element";
import { FeatureDescriptionElement } from "./elements/feature.description.element";
import { classNames } from "../../../helpers/class-names.helper";
import { IconElement } from "../../elements/icon.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { MarkdownElement } from "../../elements/markdown.element";

export function BlockFeatureOffsetGrid({ data = {}, color }) {
  const {
    title,
    subtitle,
    cta = {},
    description,
    features,
    cols = 4,
    isDark = false,
    isPrimary = false,
  } = data;
  const { primary: primaryCta, secondary: secondaryCta } = cta;

  const colsClassNames = {
    4: "sm:grid sm:grid-cols-4 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
    3: "sm:grid sm:grid-cols-3 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
    2: "sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
    1: "sm:grid sm:grid-cols-1 sm:gap-x-8 sm:gap-y-10 lg:col-span-2",
  };

  const colsClassName =
    colsClassNames?.[cols] ||
    "sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:col-span-2";

  return (
    <div className="overflow-hidden">
      <div className="relative max-w-screen-xl mx-auto py-12 lg:py-16 px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className={classNames([
                  isPrimary && color.text[700],
                  isDark && "text-gray-700",
                  !isPrimary && !isDark && "text-gray-100",
                ])}
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="784"
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <FeatureSubtitleElement
              title={subtitle}
              isDark={isDark}
              isPrimary={isPrimary}
              color={color}
            />
            <FeatureTitleElement
              title={title}
              isDark={isDark}
              isPrimary={isPrimary}
              color={color}
            />
            <FeatureDescriptionElement
              text={description}
              isDark={isDark}
              isPrimary={isPrimary}
              color={color}
            />

            <div className="mt-10">
              <CtaButtonsElement
                primary={primaryCta}
                secondary={secondaryCta}
                center={false}
                isDark={isDark || isPrimary}
                color={color}
              />
            </div>
          </div>
          <div className={classNames(["mt-10 lg:mt-0", colsClassName])}>
            {Array.isArray(features) && (
              <ul className={colsClassName}>
                {features.map(
                  ({ title, prose = false, description, icon }, idx) => {
                    return (
                      <li
                        className={classNames([idx > 0 && "mt-10 sm:mt-0"])}
                        key={title}
                      >
                        <div
                          className={classNames([
                            "flex items-center justify-center h-12 w-12 rounded-md",
                            isPrimary && `${color.bg[600]} text-white`,
                            isDark && "bg-gray-600 text-white",
                            !isPrimary &&
                              !isDark &&
                              `${color.bg[500]} text-white`,
                          ])}
                        >
                          <span className="inline text-center h-6 w-6 text-lg">
                            <IconElement icon={icon} />
                          </span>
                        </div>

                        <div className="mt-5">
                          <strong
                            className={classNames([
                              "text-lg leading-6 font-normal font-serif",
                              isPrimary && color.text[100],
                              isDark && "text-gray-100",
                              !isPrimary && !isDark && "text-gray-900",
                            ])}
                          >
                            {title}
                          </strong>
                          <div
                            className={classNames([
                              "mt-2 text-base leading-6",
                              isPrimary && color.text[200],
                              isDark && "text-gray-400",
                              !isPrimary && !isDark && "text-gray-500",
                            ])}
                          >
                            <MarkdownElement
                              isPrimary={isPrimary}
                              isDark={isDark}
                              color={color}
                              proseMode={prose}
                              size="md"
                            >
                              {description}
                            </MarkdownElement>
                          </div>
                        </div>
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { BlockFeatureOffsetGrid as default };
