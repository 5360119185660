import React, { Fragment } from "react";
import Img from "gatsby-image";
import { HeroTitleElement } from "./elements/hero.title.element";
import { HeroLeadElement } from "./elements/hero.lead.element";
import { classNames } from "../../../helpers/class-names.helper";
import { NavbarTopCenteredElement } from "../../elements/navbar/navbar.top.centered.element";
import { CtaButtonsElement } from "../../elements/cta.buttons.element";
import { TitleLabelElement } from "../../elements/title-label.element";

export function BlockHeroSplitImageOffset({ data, assets, color }) {
  const {
    subtitle = null,
    title,
    lead,
    image,
    video,
    navigation = {},
    isDark = false,
    isPrimary = false,
    cta = {},
  } = data;

  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  const imageAsset = image && assets && assets[image] ? assets[image] : null;
  const videoAsset = video && assets && assets[video] ? assets[video] : null;

  return (
    <Fragment>
      <div
        className={classNames(["relative", "overflow-hidden", "pt-4 sm:pt-0"])}
      >
        <NavbarTopCenteredElement
          data={navigation}
          isDark={isDark}
          isPrimary={isPrimary}
          assets={assets}
          color={color}
        />

        <div className="relative pt-6 pb-12 sm:pb-32">
          <main className="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
            <div className="mx-auto max-w-screen-xl">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center z-10 relative">
                  <div>
                    {subtitle && (
                      <TitleLabelElement
                        text={subtitle}
                        isDark={isDark}
                        isPrimary={isPrimary}
                        color={color}
                      />
                    )}
                    <HeroTitleElement
                      title={title}
                      isDark={isDark}
                      isPrimary={isPrimary}
                      color={color}
                    />
                    <HeroLeadElement
                      text={lead}
                      isDark={isDark}
                      isPrimary={isPrimary}
                      color={color}
                    />

                    {cta && (
                      <div className="flex items-center justify-start sm:justify-center lg:justify-start">
                        <CtaButtonsElement
                          primary={primaryCta}
                          secondary={secondaryCta}
                          centered={false}
                          isDark={isDark}
                          size="lg"
                          isPrimary={isPrimary}
                          color={color}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
                  <div className="sm:max-w-full max-h-full sm:mx-auto px-4 py-0 sm:px-0 md:-mr-12 mr-0 flex items-center h-full">
                    {videoAsset && (
                      <video
                        className="pointer-events-none w-full md:absolute md:left-1/2 md:w-1/2"
                        style={{ zIndex: 0 }}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        controls={false}
                        poster={imageAsset?.src?.publicURL}
                      >
                        <source
                          src={videoAsset?.src?.publicURL}
                          type={`video/${videoAsset?.src?.extension}`}
                        />
                      </video>
                    )}

                    {imageAsset && !videoAsset && (
                      <Img
                        className="w-full"
                        fluid={imageAsset.src.childImageSharp?.fluid}
                        alt={imageAsset.alt}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Fragment>
  );
}

export { BlockHeroSplitImageOffset as default };
