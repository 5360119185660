// import { BlockHeaderLeftDark } from "./header/block.header.left-dark";
// import { BlockHeaderLeftLight } from "./header/block.header.left-light";
// import { BlockHeaderBold } from "./header/block.header.bold";
import { BlockCtaImage } from "./cta/block.cta.image";
import { BlockCtaCenter } from "./cta/block.cta.center";
import { BlockCtaLeft } from "./cta/block.cta.left";
import { BlockCta } from "./cta/block.cta";
import { BlockCtaSplitImage } from "./cta/block.cta.split-image";
import { BlockHeroDefault } from "./hero/block.hero.default";
import { BlockHeroSplitImage } from "./hero/block.hero.split-image";
import { BlockHeroSplitImageAngled } from "./hero/block.hero.split-image-angled";
import { BlockHeroImage } from "./hero/block.hero.image";
// import { BlockHeroForm } from "./hero/block.hero.form";
import { BlockHeroVideo } from "./hero/block.hero.video";
import { BlockFeatureGrid } from "./feature/block.feature.grid";
import { BlockFeatureList } from "./feature/block.feature.list";
import { BlockFeatureOffsetGrid } from "./feature/block.feature.offset-grid";
import { BlockFeatureOffsetList } from "./feature/block.feature.offset-list";
// import { BlockFeaturePureCols } from "./feature/block.feature.pure-cols";
import { BlockFeatureSplit } from "./feature/block.feature.split";
// import { BlockPricingSidebarSplit } from "./pricing/block.pricing.sidebar-split";
// import { BlockPricingSingleFeatures } from "./pricing/block.pricing.single-features";
// import { BlockPricingSingle } from "./pricing/block.pricing.single";
// import { BlockPricingTierCols } from "./pricing/block.pricing.tier-cols";
// import { BlockPricingTierCompare } from "./pricing/block.pricing.tier-compare";
// import { BlockPricingTierEmphasized } from "./pricing/block.pricing.tier-emphasized";
// import { BlockPricingTierEqual } from "./pricing/block.pricing.tier-equal";
// import { BlockFaqCollapse } from "./faq/block.faq.collapse";
import { BlockFaqCols } from "./faq/block.faq.cols";
// import { BlockFaqOffsetExpanded } from "./faq/block.faq.offset-expanded";
// import { BlockFaqSideBySide } from "./faq/block.faq.side-by-side";
// import { BlockFaqWideCols } from "./faq/block.faq.wide-cols";
// import { BlockNewsletterContainer } from "./newsletter/block.newsletter.container";
// import { BlockNewsletterStacked } from "./newsletter/block.newsletter.stacked";
// import { BlockNewsletterStandout } from "./newsletter/block.newsletter.standout";
// import { BlockStatsCard } from "./stats/block.stats.card";
// import { BlockStatsSimple } from "./stats/block.stats.simple";
// import { BlockStatsSplitImage } from "./stats/block.stats.split-image";
import { BlockTestimonialAvatar } from "./testimonial/block.testimonial.avatar";
// import { BlockTestimonialSideBySide } from "./testimonial/block.testimonial.side-by-side";
// import { BlockTestimonialSimple } from "./testimonial/block.testimonial.simple";
// import { BlockBlogBadges } from "./blog/block.blog.badges";
// import { BlockBlogCard } from "./blog/block.blog.card";
// import { BlockBlogCols } from "./blog/block.blog.cols";
// import { BlockContactImageForm } from "./contact/block-contact.image-form";
// import { BlockContactAddressDepartment } from "./contact/block.contact.address-department";
// import { BlockContactAddressForm } from "./contact/block.contact.address-form";
// import { BlockContactAddress } from "./contact/block.contact.address";
// import { BlockContactCenterForm } from "./contact/block.contact.center-form";
// import { BlockTeamCard } from "./team/block.team.card";
// import { BlockTeamLargeRound } from "./team/block.team.large-round";
// import { BlockTeamLarge } from "./team/block.team.large";
// import { BlockTeamOffsetLarge } from "./team/block.team.offset-large";
// import { BlockTeamOffsetPortrait } from "./team/block.team.offset-portrait";
// import { BlockTeamOffsetSmall } from "./team/block.team.offset-small";
// import { BlockTeamPortrait } from "./team/block.team.portrait";
// import { BlockTeamSmall } from "./team/block.team.small";
// import { BlockContentCols } from "./content/block.content.cols";
// import { BlockContentInsetQuote } from "./content/block.content.inset-quote";
// import { BlockContentSimple } from "./content/block.content.simple";
// import { BlockContentSplitImage } from "./content/block.content.split-image";
// import { BlockContentTwoCols } from "./content/block.content.two-cols";
// import { BlockFooterMinimal } from "./footer/block.footer.minimal";
import { BlockFooterMission } from "./footer/block.footer.mission";
// import { BlockFooterReduced } from "./footer/block.footer.reduced";
// import { BlockFooterSubscription } from "./footer/block.footer.subscription";
// import { BlockFooterLocalization } from "./footer/block.footer.localization";
import { BlockLogoGrid } from "./logo/block.logo.grid";
// import { BlockLogoOffset } from "./logo/block.logo.offset";
// import { BlockLogoRow } from "./logo/block.logo.row";
// import { BlockLogoSimple } from "./logo/block.logo.simple";
// import { BlockLogoSplit } from "./logo/block.logo.split";
import { BlockBannerSticky } from "./banner/block.banner.sticky";
import { BlockBannerFloating } from "./banner/block.banner.floating";
import { BlockHeroSplitImageFloating } from "./hero/block.hero.split-image-floating";
import { BlockImageUnpadded } from "./image/block.image.unpadded";
import { BlockImageCentered } from "./image/block.image.centered";
import { BlockFormModal } from "./form/block.form.modal";
import { BlockFormEmbedded } from "./form/block.form.embedded";
import { BlockHeroSplitImageOffset } from "./hero/block.hero.split-image-offset";

import loadable from "@loadable/component";
import { BlockFormEmbeddedEmail } from "./form/block.form.embedded-email";
import { BlockReportTwentyNineFive } from "./report/block.report-twenty-nine-five";
import { BlockHeroNavigator } from "./hero/block.hero.navigator";
import { BlockHeroChart } from "./hero/block.hero.chart";
import { BlockWebinarPromo } from "./webinar/block.webinar.promo";
import BlockCalendlyInline from "./calendly/block.calendly.inline";
import BlockAssessmentCx from "./assessment/block.assessment.cx";
import { BlockLandingDefault } from "./landing/block.landing.default";

const BlockHeaderLeftDark = loadable(() =>
  import("./header/block.header.left-dark")
);
const BlockHeaderLeftLight = loadable(() =>
  import("./header/block.header.left-light")
);
const BlockHeaderBold = loadable(() => import("./header/block.header.bold"));
// const BlockCtaImage = loadable(() => import("./cta/block.cta.image"));
// const BlockCtaCenter = loadable(() => import("./cta/block.cta.center"));
// const BlockCtaLeft = loadable(() => import("./cta/block.cta.left"));
// const BlockCta = loadable(() => import("./cta/block.cta"));
// const BlockCtaSplitImage = loadable(() => import("./cta/block.cta.split-image"));
// const BlockHeroDefault = loadable(() => import("./hero/block.hero.default"));
// const BlockHeroSplitImage = loadable(() => import("./hero/block.hero.split-image"));
// const BlockHeroSplitImageAngled = loadable(() => import("./hero/block.hero.split-image-angled"));
// const BlockHeroImage = loadable(() => import("./hero/block.hero.image"));
const BlockHeroForm = loadable(() => import("./hero/block.hero.form"));
// const BlockHeroVideo = loadable(() => import("./hero/block.hero.video"));
// const BlockFeatureGrid = loadable(() => import("./feature/block.feature.grid"));
// const BlockFeatureList = loadable(() => import("./feature/block.feature.list"));
// const BlockFeatureOffsetGrid = loadable(() => import("./feature/block.feature.offset-grid"));
// const BlockFeatureOffsetList = loadable(() => import("./feature/block.feature.offset-list"));
const BlockFeaturePureCols = loadable(() =>
  import("./feature/block.feature.pure-cols")
);
// const BlockFeatureSplit = loadable(() => import("./feature/block.feature.split"));
const BlockPricingSidebarSplit = loadable(() =>
  import("./pricing/block.pricing.sidebar-split")
);
const BlockPricingSingleFeatures = loadable(() =>
  import("./pricing/block.pricing.single-features")
);
const BlockPricingSingle = loadable(() =>
  import("./pricing/block.pricing.single")
);
const BlockPricingTierCols = loadable(() =>
  import("./pricing/block.pricing.tier-cols")
);
const BlockPricingTierCompare = loadable(() =>
  import("./pricing/block.pricing.tier-compare")
);
const BlockPricingTierEmphasized = loadable(() =>
  import("./pricing/block.pricing.tier-emphasized")
);
const BlockPricingTierEqual = loadable(() =>
  import("./pricing/block.pricing.tier-equal")
);
const BlockFaqCollapse = loadable(() => import("./faq/block.faq.collapse"));
// const BlockFaqCols = loadable(() => import("./faq/block.faq.cols"));
const BlockFaqOffsetExpanded = loadable(() =>
  import("./faq/block.faq.offset-expanded")
);
const BlockFaqSideBySide = loadable(() =>
  import("./faq/block.faq.side-by-side")
);
const BlockFaqWideCols = loadable(() => import("./faq/block.faq.wide-cols"));
const BlockNewsletterContainer = loadable(() =>
  import("./newsletter/block.newsletter.container")
);
const BlockNewsletterStacked = loadable(() =>
  import("./newsletter/block.newsletter.stacked")
);
const BlockNewsletterStandout = loadable(() =>
  import("./newsletter/block.newsletter.standout")
);
const BlockStatsCard = loadable(() => import("./stats/block.stats.card"));
const BlockStatsSimple = loadable(() => import("./stats/block.stats.simple"));
const BlockStatsSplitImage = loadable(() =>
  import("./stats/block.stats.split-image")
);
// const BlockTestimonialAvatar = loadable(() => import("./testimonial/block.testimonial.avatar"));
const BlockTestimonialSideBySide = loadable(() =>
  import("./testimonial/block.testimonial.side-by-side")
);
const BlockTestimonialSimple = loadable(() =>
  import("./testimonial/block.testimonial.simple")
);
const BlockBlogBadges = loadable(() => import("./blog/block.blog.badges"));
const BlockBlogCard = loadable(() => import("./blog/block.blog.card"));
const BlockBlogCols = loadable(() => import("./blog/block.blog.cols"));
const BlockContactImageForm = loadable(() =>
  import("./contact/block-contact.image-form")
);
const BlockContactAddressDepartment = loadable(() =>
  import("./contact/block.contact.address-department")
);
const BlockContactAddressForm = loadable(() =>
  import("./contact/block.contact.address-form")
);
const BlockContactAddress = loadable(() =>
  import("./contact/block.contact.address")
);
const BlockContactCenterForm = loadable(() =>
  import("./contact/block.contact.center-form")
);
const BlockTeamCard = loadable(() => import("./team/block.team.card"));
const BlockTeamLargeRound = loadable(() =>
  import("./team/block.team.large-round")
);
const BlockTeamLarge = loadable(() => import("./team/block.team.large"));
const BlockTeamOffsetLarge = loadable(() =>
  import("./team/block.team.offset-large")
);
const BlockTeamOffsetPortrait = loadable(() =>
  import("./team/block.team.offset-portrait")
);
const BlockTeamOffsetSmall = loadable(() =>
  import("./team/block.team.offset-small")
);
const BlockTeamPortrait = loadable(() => import("./team/block.team.portrait"));
const BlockTeamSmall = loadable(() => import("./team/block.team.small"));
const BlockContentCols = loadable(() => import("./content/block.content.cols"));
const BlockContentInsetQuote = loadable(() =>
  import("./content/block.content.inset-quote")
);
const BlockContentSimple = loadable(() =>
  import("./content/block.content.simple")
);
const BlockContentSplitImage = loadable(() =>
  import("./content/block.content.split-image")
);
const BlockContentTwoCols = loadable(() =>
  import("./content/block.content.two-cols")
);
const BlockFooterMinimal = loadable(() =>
  import("./footer/block.footer.minimal")
);
// const BlockFooterMission = loadable(() => import("./footer/block.footer.mission"));
const BlockFooterReduced = loadable(() =>
  import("./footer/block.footer.reduced")
);
const BlockFooterSubscription = loadable(() =>
  import("./footer/block.footer.subscription")
);
const BlockFooterLocalization = loadable(() =>
  import("./footer/block.footer.localization")
);
// const BlockLogoGrid = loadable(() => import("./logo/block.logo.grid"));
const BlockLogoOffset = loadable(() => import("./logo/block.logo.offset"));
const BlockLogoRow = loadable(() => import("./logo/block.logo.row"));
const BlockLogoSimple = loadable(() => import("./logo/block.logo.simple"));
const BlockLogoSplit = loadable(() => import("./logo/block.logo.split"));
// const BlockBannerSticky = loadable(() => import("./banner/block.banner.sticky"));
// const BlockBannerFloating = loadable(() => import("./banner/block.banner.floating"));
// const BlockHeroSplitImageFloating = loadable(() => import("./hero/block.hero.split-image-floating"));
// const BlockImageUnpadded = loadable(() => import("./image/block.image.unpadded"));
// const BlockImageCentered = loadable(() => import("./image/block.image.centered"));

export const BLOCKS_MAP = {
  header: {
    bold: BlockHeaderBold,
    leftDark: BlockHeaderLeftDark,
    leftLight: BlockHeaderLeftLight,
  },
  cta: {
    default: BlockCta,
    image: BlockCtaImage,
    splitImage: BlockCtaSplitImage,
    center: BlockCtaCenter,
    left: BlockCtaLeft,
  },
  hero: {
    default: BlockHeroDefault,
    splitImage: BlockHeroSplitImage,
    splitImageFloating: BlockHeroSplitImageFloating,
    splitImageOffset: BlockHeroSplitImageOffset,
    splitImageAngled: BlockHeroSplitImageAngled,
    image: BlockHeroImage,
    form: BlockHeroForm,
    video: BlockHeroVideo,
    navigator: BlockHeroNavigator,
    chart: BlockHeroChart,
  },
  feature: {
    grid: BlockFeatureGrid,
    list: BlockFeatureList,
    offsetGrid: BlockFeatureOffsetGrid,
    offsetList: BlockFeatureOffsetList,
    pureCols: BlockFeaturePureCols,
    split: BlockFeatureSplit,
  },
  pricing: {
    sidebarSplit: BlockPricingSidebarSplit,
    single: BlockPricingSingle,
    singleFeatures: BlockPricingSingleFeatures,
    tierCols: BlockPricingTierCols,
    tierCompare: BlockPricingTierCompare,
    tierEmphasized: BlockPricingTierEmphasized,
    tierEqual: BlockPricingTierEqual,
  },
  faq: {
    collapse: BlockFaqCollapse,
    cols: BlockFaqCols,
    offsetExpanded: BlockFaqOffsetExpanded,
    sideBySide: BlockFaqSideBySide,
    wideCols: BlockFaqWideCols,
  },
  newsletter: {
    container: BlockNewsletterContainer,
    stacked: BlockNewsletterStacked,
    standout: BlockNewsletterStandout,
  },
  stats: {
    card: BlockStatsCard,
    simple: BlockStatsSimple,
    splitImage: BlockStatsSplitImage,
  },
  testimonial: {
    avatar: BlockTestimonialAvatar,
    sideBySide: BlockTestimonialSideBySide,
    simple: BlockTestimonialSimple,
  },
  blog: {
    badges: BlockBlogBadges,
    card: BlockBlogCard,
    cols: BlockBlogCols,
  },
  contact: {
    imageForm: BlockContactImageForm,
    addressDepartment: BlockContactAddressDepartment,
    addressForm: BlockContactAddressForm,
    address: BlockContactAddress,
    centerForm: BlockContactCenterForm,
  },
  team: {
    card: BlockTeamCard,
    largeRound: BlockTeamLargeRound,
    large: BlockTeamLarge,
    offsetLarge: BlockTeamOffsetLarge,
    offsetPortrait: BlockTeamOffsetPortrait,
    offsetSmall: BlockTeamOffsetSmall,
    portrait: BlockTeamPortrait,
    small: BlockTeamSmall,
  },
  content: {
    cols: BlockContentCols,
    insetQuote: BlockContentInsetQuote,
    simple: BlockContentSimple,
    splitImage: BlockContentSplitImage,
    twoCols: BlockContentTwoCols,
  },
  footer: {
    minimal: BlockFooterMinimal,
    mission: BlockFooterMission,
    reduced: BlockFooterReduced,
    subscription: BlockFooterSubscription,
    localization: BlockFooterLocalization,
  },
  logo: {
    grid: BlockLogoGrid,
    offset: BlockLogoOffset,
    row: BlockLogoRow,
    simple: BlockLogoSimple,
    split: BlockLogoSplit,
  },
  banner: {
    sticky: BlockBannerSticky,
    floating: BlockBannerFloating,
  },
  image: {
    unpadded: BlockImageUnpadded,
    centered: BlockImageCentered,
  },
  form: {
    modal: BlockFormModal,
    embedded: BlockFormEmbedded,
    embeddedEmail: BlockFormEmbeddedEmail,
  },
  report: {
    twentyNineFive: BlockReportTwentyNineFive,
  },
  webinar: {
    promo: BlockWebinarPromo,
  },
  calendly: {
    inline: BlockCalendlyInline,
  },
  assessment: {
    cx: BlockAssessmentCx,
  },
  landing: {
    default: BlockLandingDefault,
  },
};
