import React, { useEffect, useState } from "react";
import { FeatureTitleElement } from "./../feature/elements/feature.title.element";
import { FeatureSubtitleElement } from "./../feature/elements/feature.subtitle.element";
import { FeatureDescriptionElement } from "./../feature/elements/feature.description.element";
import { InlineWidget } from "react-calendly";

export function BlockCalendlyInline({ data = {}, color }) {
  const {
    title,
    subtitle,
    description,
    calendlyLink,
    isDark = false,
    isPrimary = false,
  } = data;
  const [calendlyUtm, setCalendlyUtm] = useState({});

  useEffect(() => {
    let utm = window?.__utm || {};
    setCalendlyUtm({
      utmCampaign: utm?.getCampaign(),
      utmContent: utm?.getContent(),
      utmMedium: utm?.getMedium(),
      utmSource: utm?.getSource(),
      utmTerm: utm?.getTerm(),
    });
  }, []);

  return (
    <div>
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="max-w-3xl mx-auto lg:text-center">
          {subtitle && (
            <FeatureSubtitleElement
              title={subtitle}
              isDark={isDark}
              isPrimary={isPrimary}
              color={color}
            />
          )}
          {title && (
            <FeatureTitleElement
              title={title}
              isDark={isDark}
              isPrimary={isPrimary}
              color={color}
            />
          )}
          {description && (
            <FeatureDescriptionElement
              text={description}
              isDark={isDark}
              isPrimary={isPrimary}
              color={color}
            />
          )}
        </div>
        <div className="calendly-inline-wrapper">
          {calendlyLink && (
            <InlineWidget
              url={calendlyLink}
              utm={calendlyUtm}
              styles={{ height: 2000 }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { BlockCalendlyInline as default };
