import { Transition } from "@headlessui/react";
import React, { useState } from "react";
import { ImageAssetElement } from "../image-asset.element";
import { NavbarTopMenuItemElement } from "./navbar.top.menu-item.element";
import { NavbarTopMenuItemMobileElement } from "./navbar.top.menu-item-mobile.element";
import { fireClickEvents } from "./../../../events/events.helper";
import classNames from "classnames";

export function NavbarTopCenteredElement({
  data = {},
  assets = {},
  color,
  isPrimary = false,
  isDark = false,
}) {
  const { logo, items = [], cta, rescue = true } = data;
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);

  return (
    <div className="relative z-50 max-w-screen-xl m-auto">
      <div className="flex justify-between items-center px-4 py-4 sm:py-4 sm:px-6 md:justify-start md:space-x-10">
        <div className="lg:w-0 lg:flex-1 ">
          <span className="flex">
            {rescue && (
              <a
                href={typeof rescue === "string" ? rescue : "/"}
                data-tracking-action-label="rescue"
                data-tracking-action-value="logo"
                data-tracking-action-position="navigation"
              >
                <ImageAssetElement
                  assetKey={logo}
                  assets={assets}
                  className="h-8 w-auto sm:h-10"
                  isFluid={true}
                />
              </a>
            )}

            {!rescue && (
              <span
                data-tracking-action-label="rescue.trap"
                data-tracking-action-value="logo"
                data-tracking-action-position="navigation"
              >
                <ImageAssetElement
                  assetKey={logo}
                  assets={assets}
                  className="h-8 w-auto sm:h-10"
                  isFluid={true}
                />
              </span>
            )}
          </span>
        </div>

        {!!items.length && (
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              onClick={() => setIsMobileNavigationOpen(true)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        )}

        <nav className="hidden md:flex space-x-10">
          {Array.isArray(items) &&
            items.map((item, idx) => {
              const unique = item?.title + idx;
              return (
                <NavbarTopMenuItemElement
                  {...item}
                  isPrimary={isPrimary}
                  isDark={isDark}
                  color={color}
                  key={unique}
                />
              );
            })}
        </nav>

        {/* <!-- todo: CTA's --!> */}
        <div className="hidden md:flex items-center justify-end space-x-8 md:flex-1 lg:w-0">
          {cta?.secondary && (
            <a
              href={cta.secondary?.link || "#"}
              onClick={() => fireClickEvents(cta.secondary?.events)}
              className={classNames({
                "whitespace-nowrap text-base leading-6 font-medium focus:outline-none": true,
                "text-gray-800 hover:text-gray-900 focus:text-gray-900":
                  !isDark && !isPrimary,
                [`text-gray-100 hover:text-gray-50 focus:text-gray-50`]: isDark,
                [`${color.text[100]} hover:${color.text[50]} focus:${color.text[50]}`]: isPrimary,
              })}
              data-tracking-action-label="CTA"
              data-tracking-action-value={cta.secondary?.title}
              data-tracking-action-position="navigation"
            >
              {cta.secondary?.title}
            </a>
          )}
          {cta?.primary && (
            <span className="inline-flex rounded-md shadow-sm">
              <a
                href={cta.primary?.link || "#"}
                onClick={() => fireClickEvents(cta.primary?.events)}
                className={`whitespace-nowrap cursor-pointer inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white ${color.bg[600]} hover:${color.bg[500]} focus:outline-none focus:${color.border[700]} focus:shadow-outline-indigo active:${color.bg[700]} transition ease-in-out duration-150`}
                data-tracking-action-label="CTA"
                data-tracking-action-value={cta.primary?.title}
                data-tracking-action-position="navigation"
              >
                {cta.primary?.title}
              </a>
            </span>
          )}
        </div>
      </div>

      <Transition
        show={isMobileNavigationOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="fixed inset-0 transition transform origin-top-right md:hidden">
          <div className="">
            <div className="bg-white h-screen overflow-y-auto divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-4 space-y-6">
                <div className="flex items-center justify-between sticky top-0 bg-white z-20">
                  <div>
                    <ImageAssetElement
                      assetKey={logo}
                      assets={assets}
                      className="h-8 w-auto"
                      isFluid={true}
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      onClick={() => setIsMobileNavigationOpen(false)}
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="mt-8 grid grid-cols-1 gap-7">
                    {Array.isArray(items) &&
                      items.map((item, idx) => {
                        const unique = item?.title + idx;
                        return (
                          <NavbarTopMenuItemMobileElement
                            {...item}
                            isPrimary={isPrimary}
                            isDark={isDark}
                            color={color}
                            key={unique}
                          />
                        );
                      })}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
