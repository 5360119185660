import React from "react";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { TitleLabelElement } from "./../../elements/title-label.element";
import { DescriptionElement } from "./../../elements/description.element";
import { CtaButtonsElement } from "./../../elements/cta.buttons.element";

export function BlockLogoGrid({ color, data, assets }) {
  const {
    subtitle,
    title,
    lead,
    logos,
    isDark = false,
    isPrimary = false,
    cta = {},
  } = data;
  const { primary: primaryCta = null, secondary: secondaryCta = null } = cta;

  return (
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      {subtitle && (
        <TitleLabelElement
          text={subtitle}
          isDark={isDark}
          isPrimary={isPrimary}
          color={color}
        />
      )}

      {title && (
        <p className="text-center text-base leading-6 font-semibold uppercase text-gray-700 tracking-wider">
          {title}
        </p>
      )}

      {lead && (
        <DescriptionElement
          text={lead}
          isDark={isDark}
          isPrimary={isPrimary}
          color={color}
        />
      )}

      {cta && (primaryCta || secondaryCta) && (
        <CtaButtonsElement
          primary={primaryCta}
          secondary={secondaryCta}
          centered={false}
          isDark={isDark}
          size="lg"
          isPrimary={isPrimary}
          color={color}
        />
      )}
      <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
        {logos.map(logo => {
          return (
            <div
              key={logo}
              className="col-span-1 flex justify-center px-4 py-2 md:py-6 md:px-8 bg-gray-50"
            >
              <ImageAssetElement
                assetKey={logo}
                assets={assets}
                objectFit="contain"
                objectPosition="center center"
                className="h-20 object-center"
                isFluid={false}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { BlockLogoGrid as default };
