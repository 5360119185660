import React from "react";
import Layout from "../components/layout";
import { BlocksLoader } from "../components/blocks/blocks-loader";

export function Page({ pageContext, path }) {
  const { blocks = [], color, siteConfig } = pageContext;
  return (
    <Layout pageContext={pageContext} siteConfig={siteConfig} path={path}>
      <BlocksLoader color={color} blocks={blocks} siteConfig={siteConfig} />
    </Layout>
  );
}

export default Page;
