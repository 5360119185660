import React from "react";
import Img from "gatsby-image";

export function BlockImageCentered({data, assets}) {
  const { image } = data;
  const imageAsset = image && assets && assets[image] ? assets[image] : null;

  return (
    <div className="relative overflow-hidden max-w-screen-xl m-auto py-4">
      <Img fluid={imageAsset.src.childImageSharp.fluid}
           className="max-w-3xl block m-auto w-full"/>
    </div>
  );
}

export { BlockImageCentered as default }
