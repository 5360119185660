import React, { Fragment } from "react";

function transformTitle(title) {
  return title.replace(/\[shy\]/g, "­"); // IMPORTANT: THIS IS NOT AN EMPTY STRING - ITS A &shy; dash
}

export function HeroTitleElement({
  title,
  isDark = false,
  isPrimary = false,
  color,
  className,
}) {
  const baseClassNames =
    "mt-4 text-4xl font-bold font-serif sm:text-5xl sm:tracking-tight lg:text-6xl";
  // "mt-4 text-3xl leading-10 font-light font-serif sm:mt-5 sm:leading-none sm:text-4xl lg:mt-6 lg:text-4xl xl:text-5xl";
  const colorClassNames = isDark || isPrimary ? "text-white" : "text-gray-900";
  const classNames = [className || "", baseClassNames, colorClassNames].join(
    " "
  );

  return (
    <Fragment>
      {title && (
        <h1 className={classNames}>
          {title && typeof title === "string" && (
            <span className="block">{transformTitle(title)}</span>
          )}
          {title &&
            Array.isArray(title) &&
            title.map((titleChunk, idx) => {
              const classNames =
                idx % 2 && !(isPrimary || isDark)
                  ? `block ${color.text[500]}`
                  : isDark && idx % 2
                  ? `block ${color.text[300]}`
                  : isPrimary && idx % 2
                  ? `block ${color.text[200]}`
                  : "block";
              return (
                <span className={classNames} key={idx}>
                  {transformTitle(titleChunk)}
                </span>
              );
            })}
        </h1>
      )}
    </Fragment>
  );
}
