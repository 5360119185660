function storableValue(value) {
  try {
    return JSON.stringify(value);
  } catch {
    return value;
  }
}

function parseStoredValue(value) {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
}

export function setItem(key, value) {
  if (!window?.localStorage) {
    return null;
  }

  if (typeof key !== "string") {
    return null;
  }

  window.localStorage.setItem(key, storableValue(value));
}

export function getItem(key) {
  if (!window?.localStorage) {
    return null;
  }

  if (typeof key !== "string") {
    return null;
  }

  const value = window.localStorage.getItem(key);

  if (!value) {
    return null;
  }

  return parseStoredValue(value);
}
