import React from "react";
import { ResponsiveRadar } from "@nivo/radar";

const CHART_TYPE_MAP = {
  radar: RadarChartElement,
};

function RadarChartElement({ chart }) {
  const chartKeyed = chart.reduce((a, c) => {
    const delta = c.team - c.benchmark;
    const deltaRel = delta / c.benchmark;

    a[c.title] = { c, delta, deltaRel };
    return a;
  }, {});

  return (
    <div className="block relative w-full" style={{ paddingBottom: "100%" }}>
      <div className="absolute inset-0 bg-white">
        <ResponsiveRadar
          data={chart}
          keys={["team", "benchmark"]}
          indexBy="title"
          maxValue={5}
          margin={{ top: 0, right: 100, bottom: 0, left: 100 }}
          curve="cardinalClosed"
          borderWidth={2}
          borderColor={{ from: "color" }}
          gridLevels={5}
          gridShape="circular"
          gridLabelOffset={23}
          enableDots={true}
          dotSize={5}
          dotBorderWidth={1}
          dotBorderColor={{ from: "color" }}
          enableDotLabel={false}
          dotLabel="value"
          dotLabelYOffset={-12}
          gridLabel={({ id, anchor }) => {
            const { deltaRel } = chartKeyed[id];
            return (
              <g
                transform={`translate(${
                  anchor === "end" ? -40 : anchor === "middle" ? -20 : 0
                }, -20)`}
              >
                <text className="text-sm font-serif fill-current text-gray-700">
                  {id}
                </text>
                <text
                  className={`text-sm font-serif font-bold fill-current ${
                    deltaRel > 0 ? "text-green-500" : "text-red-500"
                  }`}
                  y={29}
                  x={4}
                  style={{
                    fontSize: 22,
                  }}
                >
                  {(deltaRel * 100).toFixed(1)}%
                </text>
              </g>
            );
          }}
          //   colors={{ scheme: "spectral" }}
          colors={({ key }) => {
            if (key === "benchmark") {
              return "rgb(26, 32, 44)";
            }

            // TODO: apply d3-scheme
            return "rgb(233, 30, 99)";
          }}
          fillOpacity={0.0}
          blendMode="multiply"
          animate={true}
          motionConfig="wobbly"
          isInteractive={false}
          tooltipFormat={value => {
            return typeof value === "number" ? value.toFixed(2) : "-";
          }}
          legendFormat={val => "gugus"}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              translateX: -80,
              translateY: 0,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: "#ccc",
              symbolSize: 12,
              symbolShape: "circle",
            },
          ]}
        />
      </div>
    </div>
  );
}

export function ChartElement({ chart, color }) {
  const { data, type } = chart;
  const chartTypeRenderer = CHART_TYPE_MAP[type];

  if (typeof chartTypeRenderer !== "function") {
    return null;
  }

  return chartTypeRenderer({ chart: data, color });
}
