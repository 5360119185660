import React, { Component, Fragment } from "react";
import { fireEvents } from "../../../../events/events.helper";
import { MarkdownElement } from "../../../elements/markdown.element";

function FormVariantStandout({
  title,
  description,
  placeholder,
  formId,
  actionURI,
  submitForm,
  ctaLabel,
  policy,
  color,
}) {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
        <div
          className={`px-6 py-6 bg-gray-800 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center`}
        >
          <div className="xl:w-0 xl:flex-1">
            {title && (
              <h2 className="text-2xl leading-8 font-bold text-white sm:text-3xl sm:leading-9">
                {title}
              </h2>
            )}

            {description && (
              <p className={`mt-3 max-w-3xl text-lg leading-6 text-gray-400`}>
                {description}
              </p>
            )}
          </div>
          <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <form
              className="sm:flex"
              id={formId}
              method="post"
              action={actionURI}
              onSubmit={submitForm}
            >
              <input
                aria-label="E-Mail"
                type="email"
                name="email"
                required
                className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out"
                placeholder={placeholder}
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  className={`w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white ${color.bg[500]} hover:${color.bg[400]} focus:outline-none focus:${color.bg[400]} transition duration-150 ease-in-out`}
                >
                  {ctaLabel}
                </button>
              </div>
            </form>

            {policy && (
              <MarkdownElement
                color={color}
                isDark={true}
                proseMode={false}
                className={`mt-3 text-sm leading-5 text-gray-400`}
              >
                {policy}
              </MarkdownElement>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function FormVariantStacked({
  title,
  description,
  placeholder,
  formId,
  actionURI,
  submitForm,
  ctaLabel,
  policy,
  color,
}) {
  return (
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl leading-9 font-bold text-gray-900 sm:text-4xl sm:leading-10">
          {title}

          {description && (
            <Fragment>
              <br className="hidden sm:inline" />
              <span className={`${color.text[600]}`}>{description}</span>
            </Fragment>
          )}
        </h2>

        <form
          className="mt-8 sm:flex"
          id={formId}
          method="post"
          action={actionURI}
          onSubmit={submitForm}
        >
          <input
            aria-label="E-Mail"
            type="email"
            name="email"
            required
            className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs"
            placeholder={placeholder}
          />
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button
              className={`w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white ${color.bg[600]} hover:${color.bg[500]} focus:outline-none focus:shadow-outline transition duration-150 ease-in-out`}
            >
              {ctaLabel}
            </button>
          </div>
        </form>

        {policy && (
          <MarkdownElement
            color={color}
            proseMode={false}
            className="mt-3 text-sm leading-5 text-gray-600"
          >
            {policy}
          </MarkdownElement>
        )}
      </div>
    </div>
  );
}

function FormVariantContainer({
  title,
  description,
  placeholder,
  formId,
  actionURI,
  submitForm,
  ctaLabel,
  policy,
  color,
}) {
  return (
    <div className="bg-gray-800">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center">
        <div className="lg:w-0 lg:flex-1">
          {title && (
            <h2 className="text-3xl leading-9 font-light font-serif text-white sm:text-4xl sm:leading-10">
              {title}
            </h2>
          )}
          {description && (
            <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-300">
              {description}
            </p>
          )}
        </div>
        <div className="mt-8 lg:mt-0 lg:ml-8">
          <form
            className="sm:flex"
            id={formId}
            method="post"
            action={actionURI}
            onSubmit={submitForm}
          >
            <input
              aria-label="E-Mail"
              type="email"
              name="email"
              required
              className="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out sm:max-w-xs"
              placeholder={placeholder}
            />
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                className={`w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white ${color.bg[500]} hover:${color.bg[400]} focus:outline-none focus:${color.bg[400]} transition duration-150 ease-in-out`}
              >
                {ctaLabel}
              </button>
            </div>
          </form>

          {policy && (
            <MarkdownElement
              color={color}
              isDark={true}
              proseMode={false}
              className="mt-3 text-sm leading-5 text-gray-300"
            >
              {policy}
            </MarkdownElement>
          )}
        </div>
      </div>
    </div>
  );
}

export class FormSharpspringNativeEmailElement extends Component {
  constructor(props) {
    super(props);

    this.localFormId = ["FID_", Math.round(Math.random() * 100000)].join("");
    this.submitForm = this.submitForm.bind(this);
    this.postFormSubmitCallback = this.postFormSubmitCallback.bind(this);
  }

  componentDidMount() {
    this.initializeNativeForm();
  }

  getScriptSrc() {
    return this.props.baseConfig?.scriptSrc;
  }

  isScriptEmbedded() {
    const scriptSrc = this.getScriptSrc();
    return !!document.querySelector(`script[src="${scriptSrc}"]`);
  }

  getFormId() {
    return this.props?.config?.formId;
  }

  getBaseURI() {
    return this.props?.baseConfig?.postbackURI;
  }

  getActionURI() {
    return this.getBaseURI() + this.getFormId();
  }

  initializeNativeForm() {
    const formId = this.getFormId();
    window.__ss_noform = window.__ss_noform || [];
    window.__ss_noform.push(["baseURI", this.getBaseURI()]);
    // window.__ss_noform.push(["endpoint", this.localFormId]); // -> https://help.sharpspring.com/hc/en-us/articles/115001065908-Tracking-Multiple-Native-Forms-on-a-Single-Page#h_951716390551525967719738
    window.__ss_noform.push(["form", formId]);
    window.__ss_noform.push(["submitType", "manual"]);

    if (!this.isScriptEmbedded()) {
      const scriptSrc = this.getScriptSrc();
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("async", "async");
      scriptTag.setAttribute("src", scriptSrc);
      document.body.appendChild(scriptTag);
    }
  }

  postFormSubmitCallback() {
    this.conversionSuccess();
  }

  submitForm(evt) {
    evt.preventDefault();
    window.__ss_noform.push([
      "submit",
      this.postFormSubmitCallback,
      this.getFormId(),
    ]);
  }

  redirectPage({ url }) {
    window.location.href = url;
  }

  conversionSuccess() {
    const { conversion } = this.props?.data;

    if (!conversion) {
      return;
    }

    const { events, redirect } = conversion;

    if (Array.isArray(events) && events.length) {
      fireEvents(events);
    }

    if (typeof redirect === "string") {
      this.redirectPage({ url: redirect });
    }
  }

  render() {
    const { color, data } = this.props || {};
    const {
      title,
      description,
      placeholder,
      ctaLabel,
      policy,
      variant = "container",
    } = data || {};

    const formElementData = {
      formId: this.getFormId(),
      title,
      description,
      placeholder,
      actionURI: this.getActionURI(),
      submitForm: this.submitForm.bind(this),
      ctaLabel,
      policy,
      color,
    };

    switch (variant) {
      case "container":
        return FormVariantContainer(formElementData);
      case "stacked":
        return FormVariantStacked(formElementData);
      case "standout":
        return FormVariantStandout(formElementData);
      default:
        return FormVariantContainer(formElementData);
    }
  }
}
