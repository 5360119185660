import React from "react";
import { IconElement } from "./icon.element";

export function LoadingElement({ loading }) {
  if (!loading) {
    return null;
  }

  return (
    <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-center py-36">
        <span className="flex items-center justify-center w-12 h-12 spin text-4xl text-gray-500 animate-spin">
          <IconElement icon="spinnerThird" />
        </span>
      </div>
    </div>
  );
}
