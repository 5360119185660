import React, { Fragment } from "react";
import classNames from "classnames";

export function SectionTitleElement({
  size = "xl",
  text,
  isDark = false,
  isPrimary = false,
  isCenter = false,
  color,
}) {
  return (
    <Fragment>
      {text && (
        <h2
          className={classNames({
            "font-bold font-serif sm:tracking-tight": true,
            // "font-light font-serif tracking-tight": true,
            "text-3xl sm:text-6xl leading-9 sm:leading-10": size === "6xl",
            "text-3xl sm:text-5xl leading-9 sm:leading-10": size === "5xl",
            "text-3xl sm:text-4xl leading-9 sm:leading-10": size === "4xl",
            "text-2xl sm:text-3xl leading-9 sm:leading-10": size === "3xl",
            "text-xl sm:text-2xl leading-9 sm:leading-10": size === "2xl",
            "text-lg sm:text-xl leading-9 sm:leading-10": size === "xl",
            "text-base sm:text-lg leading-9 sm:leading-10": size === "lg",
            "text-gray-100": isDark,
            "text-white": isPrimary,
            "text-gray-900": !isDark && !isPrimary,
            "text-left md:text-center": isCenter,
          })}
        >
          {text && typeof text === "string" && (
            <span className="block">{text}</span>
          )}
          {text &&
            Array.isArray(text) &&
            text.map((textChunk, idx) => {
              const classNamesString =
                idx % 2 && !(isPrimary || isDark)
                  ? `block ${color.text[600]}`
                  : isDark && idx % 2
                  ? `block ${color.text[400]}`
                  : "block";
              return (
                <strong className={classNamesString} key={idx}>
                  {textChunk}
                </strong>
              );
            })}
        </h2>
      )}
    </Fragment>
  );
}
