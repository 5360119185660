import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { IconElement } from "../../elements/icon.element";
import { LoadingElement } from "../../elements/loading.element";
import { scaleLinear } from "d3-scale";
import { ResponsiveRadar } from "@nivo/radar";
import { getItem, setItem } from "../../../helpers/localstorage.helper";
import { DividerTitleElement } from "../../elements/divider-title.element";
import { Fragment } from "react-is";
import { ImageAssetElement } from "../../elements/image-asset.element";
import { BlockFormNested } from "../form/block.form.nested";
import { BlockCalendlyInline } from "../calendly/block.calendly.inline";
// import { DividerLabelElement } from "../../elements/divider-label.element";
// import { CircularProgressElement } from "../../elements/circular-progress.element";

// https://www.gatsbyjs.com/docs/debugging-html-builds/
const isBrowser = typeof window !== "undefined";

const STEP = {
  SURVEY: "SURVEY",
  REPORT: "REPORT",
};

function extractQuestionReport(report) {
  if (!(report && typeof report === "object")) {
    return null;
  }

  const topics = report?.topics;

  if (!topics) {
    return null;
  }

  const questionResults = [];

  const topicsList = Object.entries(topics);

  for (const [, { questions }] of topicsList) {
    questionResults.push(
      ...Object.entries(questions).map(([questionKey, data]) => {
        return {
          key: questionKey,
          ...data,
        };
      })
    );
  }

  return questionResults;
}

function AssessmentCxRadar({ labels, report }) {
  if (!report) {
    return null;
  }

  const chart = extractQuestionReport(report);
  const chartKeyed = chart.reduce((a, { key, absolute, relative }) => {
    a[key] = { absolute, relative };
    return a;
  }, {});

  return (
    <div className="block relative w-full" style={{ paddingBottom: "100%" }}>
      <div className="absolute inset-0 bg-white">
        <ResponsiveRadar
          data={chart}
          keys={["absolute"]}
          indexBy="key"
          maxValue={4}
          margin={{ top: 0, right: 120, bottom: 0, left: 100 }}
          // curve="catmullRomClosed"
          borderWidth={2}
          //   borderColor={{ from: "color" }}
          gridLevels={5}
          gridShape="linear"
          gridLabelOffset={23}
          enableDots={true}
          dotSize={4}
          dotBorderWidth={2}
          //   enableDotLabel={true}
          //   dotLabel="absolute"
          dotLabelYOffset={-12}
          gridLabel={({ id, anchor }) => {
            const value = chartKeyed[id];
            const questionLabelSource = labels?.questions[id];
            const {
              name: label,
              topic: { color },
            } = questionLabelSource;
            return (
              <g
                transform={`translate(${
                  anchor === "end" ? -80 : anchor === "middle" ? -80 : -10
                }, -10)`}
              >
                <text
                  className="text-sm font-sans fill-current text-gray-700"
                  style={{ fontSize: 14 }}
                >
                  <tspan x="0" dy="0" width={20}>
                    {label}
                  </tspan>
                </text>
                <text
                  className={`text-sm font-sans font-bold fill-current`}
                  y={24}
                  x={4}
                  style={{
                    fontSize: 18,
                    color,
                  }}
                >
                  {Math.round(value.relative * 100)}%
                </text>
              </g>
            );
          }}
          //   colors={{ scheme: "spectral" }}
          colors={({ key }) => {
            // Todo: colors by key?
            return "rgb(7,7,7)"; // "rgb(233, 30, 99)";
          }}
          fillOpacity={0.0}
          blendMode="multiply"
          animate={true}
          motionConfig="wobbly"
          isInteractive={false}
          //   tooltipFormat={value => {
          //     return typeof value === "number" ? value.toFixed(2) : "-";
          //   }}
          //   legendFormat={val => "gugus"}
          //   legends={[
          //     {
          //       anchor: "bottom",
          //       direction: "row",
          //       translateX: -80,
          //       translateY: 0,
          //       itemWidth: 80,
          //       itemHeight: 20,
          //       itemTextColor: "#ccc",
          //       symbolSize: 12,
          //       symbolShape: "circle",
          //     },
          //   ]}
        />
      </div>
    </div>
  );
}

function AssessmentTitle({ icon, title, description }) {
  return (
    <div className="bg-white">
      <div className="max-w-screen-lg mx-auto py-12 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
        <div className="max-w-2xl">
          {icon && (
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-xmblue-600 from-xmpurple to-xmpink bg-gradient-to-br text-white text-2xl mb-6 lg:mb-8 lg:h-20 lg:w-20 lg:text-5xl">
              <IconElement icon={icon} />
            </div>
          )}

          {title && (
            <h2 className="mt-4 text-4xl tracking-tight font-extrabold text-gray-900 sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
              {title}
            </h2>
          )}
          {description && (
            <p className="mt-5 leading-snug text-lg sm:text-xl text-gray-700">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function AssessmentQuestionOption({ option, isActive, updateResult, color }) {
  const { answer, value } = option || {};

  if (!(typeof answer === "string" && typeof value === "number")) {
    return null;
  }

  const icon = isActive ? "check" : "circle";

  return (
    <button
      className={classNames({
        "ring-0 outline-none active:ring-0 focus:ring-1 focus:ring-current active:outline-none focus:outline-none text-left relative rounded-md border-0 px-4 py-3 shadow-xs flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 cursor-pointer group transition duration-400": true,
        "border-gray-300 bg-white hover:border-gray-400 focus-within:ring-blue-500 hover:bg-gray-200": !isActive,
        "bg-blue-500 text-blue-50": isActive,
      })}
      style={{ backgroundColor: isActive ? color : null }}
      onClick={() => {
        updateResult(isActive ? null : value);
      }}
      data-tracking-action-label="answer.option"
      data-tracking-action-value={`${isActive ? "(off)" : "(on)"} ${answer}`}
      data-tracking-action-position="survey question"
    >
      <div
        className={classNames({
          "flex-shrink-0 flex items-center justify-center mr-2": true,
          "text-gray-300": !isActive,
        })}
      >
        <IconElement icon={icon} />
      </div>
      <div className="flex-1 min-w-0 flex items-center">
        <p
          className={classNames({
            "text-sm font-normal transition duration-400": true,
            "text-gray-800": !isActive,
            "text-blue-50": isActive,
          })}
        >
          {answer}
        </p>
      </div>
    </button>
  );
}

function AssessmentQuestionOptions({ options, result, updateResult, color }) {
  if (!Array.isArray(options)) {
    return null;
  }

  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
      {options.map(option => (
        <AssessmentQuestionOption
          option={option}
          key={option.value}
          isActive={result === option.value}
          updateResult={updateResult}
          color={color}
        />
      ))}
    </div>
  );
}

function AssessmentQuestionTypo({ question }) {
  return (
    <h3 className="font-bold font-sans text-xl sm:text-2xl lg:text-2xl text-gray-900">
      {question}
    </h3>
  );
}

function AssessmentQuestion({ question, result, updateResult, topic }) {
  return (
    <div className="bg-white rounded-lg border-b mb-8">
      <div className="pt-4 pb-2">
        <AssessmentQuestionTypo question={question.question} />
      </div>
      <div className="py-5 sm:py-6">
        <AssessmentQuestionOptions
          options={question.options}
          result={result}
          updateResult={updateResult}
          color={topic.color}
        />
      </div>
    </div>
  );
}

function AssessmentQuestions({ topic, questions, result, updateResult }) {
  if (!Array.isArray(questions)) {
    return null;
  }

  return (
    <div>
      {questions.map(question => (
        <div className="my-6" key={question.key}>
          <AssessmentQuestion
            topic={topic}
            question={question}
            key={question.key}
            result={result[question.key]}
            updateResult={value => updateResult(question.key, value)}
          />
        </div>
      ))}
    </div>
  );
}

function AssessmentTopicTitle({ title, progress, color }) {
  return (
    <div className="sticky top-0 z-10 bg-white py-4">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex items-center justify-between">
        <span className="pr-3 bg-white text-base sm:text-lg font-sans font-light text-gray-700">
          {title}
        </span>
        <div
          className={classNames({
            "flex justify-center relative rounded-full overflow-hidden bg-gray-200 transition-all duration-300 mr-0": true,
            "w-32 md:w-64 h-2": !progress.completed,
            "w-6 h-6": progress.completed,
          })}
          style={{
            backgroundColor: progress.completed ? color : null,
          }}
        >
          <div
            className={classNames({
              "absolute left-0 inset-y-0 transition-width transition-color duration-500": true,
              "bg-blue-500 ": !progress.completed,
              "bg-blue-600": progress.completed,
            })}
            style={{
              backgroundColor: color,
              width:
                (progress.answeredQuestions / progress.questions) * 100 + "%",
            }}
          ></div>
          <div
            className={classNames({
              "flex items-center text-xs text-white justify-center absolute inset-0 transform transition-transform duration-300 ease-in-out": true,
              "translate-y-full opacity-0": !progress.completed,
              "delay-300 translate-y-0 opacity-100": progress.completed,
            })}
          >
            <IconElement icon="check" />
          </div>
        </div>
      </div>
    </div>
  );
}

function AssessmentTopic({ topic, result, updateResult, progress }) {
  const { name, questions } = topic || { questions: null };

  if (!Array.isArray(questions)) {
    return null;
  }

  return (
    <div>
      <AssessmentTopicTitle
        title={name}
        progress={progress}
        color={topic.color}
      />
      <AssessmentQuestions
        topic={topic}
        questions={questions}
        updateResult={updateResult}
        result={result}
      />
    </div>
  );
}

function computeProgress(model, results) {
  return model?.reduce(
    (a, c) => {
      const topicQuestionsCount = c.questions.length;

      a.topics += 1;
      a.questions += topicQuestionsCount;

      const answeredQuestions = Object.entries(results[c.key])
        .map(([, answer]) => (typeof answer === "number" ? 1 : 0))
        .reduce((sum, currentValue) => sum + currentValue, 0);

      a.answeredQuestions += answeredQuestions;
      a.completed = a.questions === a.answeredQuestions;
      a.answeredTopics += topicQuestionsCount === answeredQuestions ? 1 : 0;

      a.topicsProgress = {
        ...a.topicsProgress,
        [c.key]: {
          questions: topicQuestionsCount,
          answeredQuestions,
          completed: topicQuestionsCount === answeredQuestions,
        },
      };

      return a;
    },
    {
      topics: 0,
      questions: 0,
      answeredQuestions: 0,
      answeredTopics: 0,
      topicsProgress: {},
      completed: false,
    }
  );
}

function computeTopicResults(scale, topicResults) {
  const linearScaleTransformer = scaleLinear([scale?.min, scale?.max], [0, 1]);

  return Object.entries(topicResults).reduce(
    (a, [questionKey, result]) => {
      a.questions[questionKey] = {
        relative: result !== null ? linearScaleTransformer(result) : null,
        absolute: result,
      };

      if (result !== null) {
        a.overall.frequencyDistribution = {
          ...a.overall.frequencyDistribution,
          [result]: (a.overall.frequencyDistribution?.[result] || 0) + 1,
        };
      }

      a.overall.questions += result !== null ? 1 : 0;
      a.overall.sum += result !== null ? result : 0;
      a.overall.min =
        (result !== null && a.overall.min > result) || a.overall.min === null
          ? result
          : a.overall.min;
      a.overall.max =
        (result !== null && a.overall.max < result) || a.overall.max === null
          ? result
          : a.overall.max;

      a.overall.average =
        a.overall.questions > 0 ? a.overall.sum / a.overall.questions : null;
      a.overall.averageRelative =
        a.overall.average !== null
          ? linearScaleTransformer(a.overall.average)
          : null;

      return a;
    },
    {
      questions: {},
      overall: {
        frequencyDistribution: {},
        average: null,
        averageRelative: null,
        min: null,
        max: null,
        sum: null,
        questions: 0,
      },
    }
  );
}

function computeReport(scale, results) {
  const linearScaleTransformer = scaleLinear([scale?.min, scale?.max], [0, 1]);

  return Object.entries(results).reduce(
    (a, [topicKey, topicResults]) => {
      const topicResult = computeTopicResults(scale, topicResults);
      a.topics[topicKey] = topicResult;

      const overall = a.overall;
      const { frequencyDistribution } = overall;

      // min / max
      const tMin = topicResult.overall.min;
      a.overall.min =
        (tMin !== null && tMin < a.overall.min) || a.overall.min === null
          ? tMin
          : a.overall.min;

      const tMax = topicResult.overall.max;
      a.overall.max =
        (tMax !== null && tMax > a.overall.max) || a.overall.max === null
          ? tMax
          : a.overall.max;

      // questions / sum / average
      a.overall.sum += topicResult.overall.sum || 0;
      a.overall.questions += topicResult.overall.questions || 0;
      a.overall.average =
        a.overall.questions > 0 ? a.overall.sum / a.overall.questions : null;

      a.overall.averageRelative =
        a.overall.average !== null
          ? linearScaleTransformer(a.overall.average)
          : null;

      // frequency distribution merge
      const tFrequencyDistribution = Object.entries(
        topicResult.overall.frequencyDistribution
      );

      for (const [scaleValue, frequency] of tFrequencyDistribution) {
        a.overall.frequencyDistribution[scaleValue] =
          (frequencyDistribution?.[scaleValue] || 0) + frequency;
      }

      return a;
    },
    {
      topics: {},
      scale: scale,
      overall: {
        frequencyDistribution: {},
        average: null,
        averageRelative: null,
        min: null,
        max: null,
        sum: null,
        questions: null,
      },
    }
  );
}

function computeModelLabels(model) {
  if (!Array.isArray(model)) {
    return null;
  }

  const modelLabels = { topics: {}, questions: {} };

  for (const topic of model) {
    modelLabels.topics[topic.key] = topic;

    if (!Array.isArray(topic?.questions)) {
      continue;
    }

    for (const question of topic.questions) {
      modelLabels.questions[question.key] = { topic, ...question };
    }
  }

  return modelLabels;
}

function AssessmentOverallInterpretation({
  scoring,
  report,
  assets,
  backAction,
}) {
  const score = report?.overall?.average;
  const sortedScoring = scoring.sort((a, b) => {
    if (a?.min > b?.min) {
      return -1;
    }

    if (a?.min < b?.min) {
      return 1;
    }
    return 0;
  });

  const interpretation = sortedScoring.find(
    scoringItem => score >= scoringItem.min
  );

  return (
    <div className="bg-xmpink bg-gradient-to-br from-xmpink to-xmpurple text-white  relative overflow-hidden">
      <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
        {interpretation && interpretation.image && (
          <ImageAssetElement
            assets={assets}
            assetKey={interpretation.image}
            className="w-full h-full"
            objectFit="cover"
            objectPosition="center center"
            isFluid={true}
          />
        )}
      </div>

      <div className="relative mx-auto max-w-7xl">
        <button
          className="relative z-10 p-4 my-4 text-2xl cursor-pointer"
          onClick={() => backAction()}
          data-tracking-action-label="navigation"
          data-tracking-action-value="arrow icon top"
          data-tracking-action-position="interpretation header"
        >
          <IconElement icon="arrowLeftThin" />
        </button>
      </div>

      <div className="relative mx-auto max-w-7xl px-4">
        <div className="max-w-2xl py-12 sm:py-16">
          {interpretation?.title && (
            <h2 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
              <span className="block">{interpretation.title}</span>
            </h2>
          )}
          {interpretation?.description && (
            <p className="max-w-xl mt-3 text-base sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              {interpretation.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function AssessmentTopicsReportingBars({ labels, scale, model, report }) {
  const topicResults = Object.entries(report?.topics || {});

  return (
    <div className="w-full">
      <ul>
        {topicResults &&
          topicResults.map(([key, { overall }]) => {
            const topicLabel = labels.topics[key];
            const relativeAvg = overall.averageRelative;
            const { scoring } = topicLabel;
            const interpretation = calculateTopicScore(
              scoring,
              overall.average
            );

            return (
              <li key={key} className="block w-full mb-8 last:mb-0">
                <div className="w-full flex justify-between mb-2">
                  <label className="pr-3 bg-white text-lg sm:text-xl lg:text-2xl font-sans font-bold text-gray-900">
                    {topicLabel.name}
                  </label>
                  <span className="pr-3 bg-white text-sm sm:text-base lg:text-lg font-sans font-normal text-gray-800">
                    {Math.round(relativeAvg * 100)}%
                  </span>
                </div>

                <div className="relative w-full h-2 rounded-full bg-gray-200 overflow-hidden">
                  <div
                    className="absolute inset-y-0 left-0 bg-current transition-width duration-300 ease-in-out rounded-r-full"
                    style={{
                      color: topicLabel.color,
                      width: relativeAvg * 100 + "%",
                    }}
                  ></div>
                </div>

                {interpretation && (
                  <div className="py-4">
                    {interpretation.title && (
                      <strong className="block text-sm font-bold text-gray-800">
                        {interpretation.title}
                      </strong>
                    )}
                    {interpretation.description && (
                      <p className="prose text-gray-700">
                        {interpretation.description}
                      </p>
                    )}
                  </div>
                )}
              </li>
            );
          })}
        <li></li>
      </ul>
    </div>
  );
}

function calculateTopicScore(scoring, score) {
  if (!(scoring && typeof score === "number")) {
    return null;
  }

  const sortedScoring = scoring.sort((a, b) => {
    if (a?.min > b?.min) {
      return -1;
    }

    if (a?.min < b?.min) {
      return 1;
    }
    return 0;
  });

  return sortedScoring.find(scoringItem => score >= scoringItem.min);
}

function calculateOptionRecommendation(question, result) {
  if (!(question && result)) {
    return null;
  }

  return question.options.find(option => option.value === result.absolute)
    ?.recommendation;
}

function AssessmentFieldRecommendationsQuestion({ topic, question, result }) {
  if (!(question && result)) {
    return null;
  }

  const recommendation = calculateOptionRecommendation(question, result);

  if (!recommendation) {
    return null;
  }

  return (
    <Fragment>
      <div className="flex-1 flex flex-col p-8 group">
        <div
          className="relative w-20 h-20 text-4xl flex-shrink-0 mx-auto bg-current rounded-full flex items-center justify-center ring-2 ring-offset-2 ring-current ring-opacity-50 "
          style={{ color: topic.color }}
        >
          <span className="text-white block">
            <IconElement icon={question.icon} />
          </span>
        </div>
        <div className="my-3">
          <span
            className="px-2 py-1 text-xs font-medium border-current border-0 rounded-full"
            style={{ color: topic.color }}
          >
            {question.name}
          </span>
        </div>
        <h3 className="mt-6 text-gray-900 text-base lg:text-lg leading-snug font-bold">
          {recommendation.title || question.name}
        </h3>
        <dl className="mt-2.5 flex-grow flex flex-col justify-between">
          <dd className="text-gray-600 prose whitespace-pre-line text-sm sm:text-base text-gray-700">
            {recommendation.description}
          </dd>
        </dl>
      </div>

      {false && (
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <a
                href={`#cde`}
                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
              >
                <IconElement
                  icon="arrowRight"
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">Email</span>
              </a>
            </div>
            <div className="-ml-px w-0 flex-1 flex">
              <a
                href={`#abc`}
                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              >
                <IconElement
                  icon="arrowRight"
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">Call</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

function AssessmentFieldRecommendations({ model, report }) {
  if (!(model && report)) {
    return null;
  }

  return (
    <div>
      {/* <DividerTitleElement title="Handlungsempfehlungen" /> */}

      {Array.isArray(model) &&
        model.map(topic => {
          // const topicReport = report.topics?.[topic.key];
          // const topicReportOverall = topicReport?.overall;
          // const {
          //   average: topicAverage,
          //   averageRelative: topicRelativeAverage,
          // } = topicReportOverall;
          // const topicScore = calculateTopicScore(topic?.scoring, topicAverage);

          return (
            <div key={topic.key} className="py-4">
              <DividerTitleElement title={topic.name} />
              <ul className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
                {Array.isArray(topic.questions) &&
                  topic.questions.map(question => (
                    <li
                      key={question.key}
                      className="col-span-1 flex flex-col text-center bg-white rounded shadow-xl hover:shadow-lg divide-y divide-gray-200 transition-all duration-1000"
                    >
                      <AssessmentFieldRecommendationsQuestion
                        topic={topic}
                        question={question}
                        result={
                          report.topics?.[topic.key]?.questions?.[question.key]
                        }
                      />
                    </li>
                  ))}
              </ul>
            </div>
          );
        })}
    </div>
  );
}

function extractTopRecommendations(model, report, itemsCount = 3) {
  if (!(model && report && typeof itemsCount === "number")) {
    return null;
  }

  const questions = Object.entries(report.topics).reduce(
    (a, [topicKey, topic]) => {
      a.push(
        ...Object.entries(topic?.questions).map(
          ([questionKey, questionResults]) => {
            const topic = model.find(mTopic => mTopic.key === topicKey);
            const question = topic.questions.find(q => q.key === questionKey);

            return {
              topic,
              question,
              key: questionKey,
              result: questionResults,
            };
          }
        )
      );
      return a;
    },
    []
  );

  const sortedQuestions = questions.sort((a, b) => {
    const aR = a.result.absolute;
    const bR = b.result.absolute;
    if (aR > bR) {
      return 1;
    }

    if (aR < bR) {
      return -1;
    }

    return 0;
  });

  return sortedQuestions.splice(0, itemsCount);
}

function AssessmentFieldTopRecommendations({ model, report, itemsCount = 3 }) {
  if (!(model && report && typeof itemsCount === "number")) {
    return null;
  }

  const topRecommendations = extractTopRecommendations(
    model,
    report,
    itemsCount
  );

  return (
    <div>
      {/* <DividerTitleElement title="Handlungsempfehlungen" /> */}

      {/* <pre>{JSON.stringify(topRecommendations, null, 4)}</pre> */}

      <div className="py-4">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {Array.isArray(topRecommendations) &&
            topRecommendations.map(question => (
              <li
                key={question.key}
                className="col-span-1 flex flex-col text-center bg-white rounded shadow-sm hover:shadow-xl divide-y divide-gray-200 transition-all duration-1000"
              >
                <AssessmentFieldRecommendationsQuestion
                  topic={question.topic}
                  question={question.question}
                  result={question.result}
                />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

function AssessmentCtaForm({ quote, form, color, siteConfig, assets }) {
  // const calendlyData = {
  //   calendlyLink: "https://calendly.com/kevin_echonovum/30minuten-austausch",
  // };

  // resetted calendly data because it looked bad
  const calendlyData = null;

  return (
    <Fragment>
      <div className="relative bg-white py-12 sm:py-12">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-12 lg:py-0">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            {quote && (
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-12">
                {/* Testimonial card*/}
                <div className="relative pt-64 pb-10 rounded-md shadow-xl overflow-hidden">
                  <div className="absolute inset-0 w-full h-full">
                    {quote.image && (
                      <ImageAssetElement
                        assets={assets}
                        assetKey={quote.image}
                        objectFit="cover"
                        objectPosition="center center"
                        isFluid={true}
                      />
                    )}
                  </div>

                  <div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />
                  <div className="absolute inset-0 bg-gradient-to-b from-xmblue to-xmblue-800 opacity-90" />
                  <div className="relative px-8">
                    <blockquote className="mt-8">
                      <div className="relative text-lg font-medium text-white md:flex-grow">
                        <svg
                          className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-400"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        {quote && quote.text && (
                          <p className="relative">{quote.text}</p>
                        )}
                      </div>

                      {quote && quote.cite && (
                        <footer className="mt-4">
                          <p className="text-base font-semibold text-indigo-200">
                            {quote.cite}
                          </p>
                        </footer>
                      )}
                    </blockquote>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              {form && form.preFormTitle && (
                <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                  {form.preFormTitle}
                </h2>
              )}
              <div className="mt-2 text-gray-500 space-y-6">
                {calendlyData && (
                  <BlockCalendlyInline color={color} data={calendlyData} />
                )}

                {form && (
                  <div className="lg:-mx-6">
                    <BlockFormNested
                      data={form}
                      assets={assets}
                      color={color}
                      siteConfig={siteConfig}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8"></div>
      </div>
    </Fragment>
  );
}

function AssessmentReporting({
  color,
  form,
  siteConfig,
  assets,
  scoring,
  model,
  scale,
  results,
  ctaQuote,
  backAction,
}) {
  const [report, setReport] = useState(null);

  useEffect(() => {
    setReport(computeReport(scale, results));
  }, [scale, results]);

  const modelLabels = computeModelLabels(model);

  return (
    <div>
      <div className="w-full">
        <AssessmentOverallInterpretation
          scoring={scoring}
          report={report}
          assets={assets}
          backAction={backAction}
        />
      </div>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-wrap flex-stretch">
          <div className="w-full lg:w-1/2 lg:pr-12 py-16">
            <AssessmentCxRadar labels={modelLabels} report={report} />
          </div>

          <div className="relative flex items-center lg:pl-12 lg:pr-12 lg:border-l w-full lg:w-1/2 pt-6">
            <div className="lg:block hidden absolute inset-0 bg-white -my-8 x-shadow-xl x-rounded-lg"></div>
            <div className="z-10">
              <AssessmentTopicsReportingBars
                scale={scale}
                labels={modelLabels}
                report={report}
                model={model}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <pre>{JSON.stringify(report, null, 4)}</pre> */}
      <div className="relative w-full py-12 mb-6 bg-gradient-to-br from-xmpurple to-xmblue">
        <div className="absolute inset-0 opacity-50 filter saturate-0 mix-blend-multiply">
          <ImageAssetElement
            assets={assets}
            assetKey="tools"
            className="w-full h-full"
            objectFit="cover"
            objectPosition="center center"
            isFluid={true}
          />
        </div>

        <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 z-10">
          <span className="block py-4 lg:py-8 lg:py-16 text-3xl font-extrabold text-white sm:text-4xl xl:text-5xl">
            Die 3 wichtigsten Handlungsfelder
          </span>

          <AssessmentFieldTopRecommendations model={model} report={report} />
        </div>
      </div>

      {form && (
        <AssessmentCtaForm
          form={form}
          assets={assets}
          color={color}
          siteConfig={siteConfig}
          quote={ctaQuote}
        />
      )}

      {/* <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full mb-6">
          <AssessmentFieldRecommendations model={model} report={report} />
        </div>
      </div> */}
    </div>
  );
}

export function BlockAssessmentCx({ siteConfig, color, assets, data }) {
  const {
    scoring,
    storageKey,
    model,
    scale,
    icon,
    title,
    description,
    form,
    ctaQuote,
  } = data || {
    storageKey: null,
    scoring: null,
    model: null,
    scale: { min: null, max: null },
    title: null,
    description: null,
    form: null,
    ctaQuote: null,
  };

  const initialResults = model?.reduce((a, c) => {
    a[c.key] = c?.questions
      ?.map(({ key }) => key)
      ?.reduce((questionKeyMap, questionKey) => {
        questionKeyMap[questionKey] = null;
        return questionKeyMap;
      }, {});
    return a;
  }, {});

  const [ready, setReady] = useState(false);

  const [results, setResults] = useState(initialResults);

  const initialProgress = {}; // computeProgress(model, results);
  const [progress, setProgress] = useState(initialProgress);

  const [step, setStep] = useState(STEP.SURVEY);

  function updateResult(topic, question, value) {
    const currentResultsClone = {
      ...results,
      [topic]: {
        ...results[topic],
        [question]: value,
      },
    };

    if (isBrowser) {
      setItem(storageKey, currentResultsClone);
    }
    setResults(currentResultsClone);
  }

  function scrollUp() {
    window.setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 200);
  }

  function goToStep(step) {
    setStep(step);

    scrollUp();
  }

  useEffect(() => {
    const storedInitialResults = getItem(storageKey);
    if (storedInitialResults) {
      setResults(storedInitialResults);
    }
  }, [storageKey]);

  useEffect(() => {
    setProgress(computeProgress(model, results));
    setReady(true);
  }, [model, results]);

  if (!model) {
    return null;
  }

  return (
    <div>
      {ready && step === STEP.SURVEY && (
        <AssessmentTitle icon={icon} title={title} description={description} />
      )}

      <LoadingElement loading={!ready} />

      {ready && step === STEP.REPORT && progress.completed && (
        <AssessmentReporting
          scoring={scoring}
          results={results}
          model={model}
          scale={scale}
          assets={assets}
          form={form}
          siteConfig={siteConfig}
          color={color}
          ctaQuote={ctaQuote}
          backAction={() => goToStep(STEP.SURVEY)}
        />
      )}

      <div
        className={classNames({
          "max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 transform transition-all duration-1000 delay-300": true,
          "opacity-0 translate-y-32": !ready,
          "opacity-100 translate-y-0": ready,
        })}
      >
        {ready &&
          step === STEP.SURVEY &&
          Array.isArray(model) &&
          model.map(
            topic =>
              results?.[topic.key] &&
              progress?.topicsProgress && (
                <AssessmentTopic
                  topic={topic}
                  key={topic.key}
                  result={results[topic.key]}
                  updateResult={(question, value) =>
                    updateResult(topic.key, question, value)
                  }
                  progress={progress.topicsProgress[topic.key]}
                />
              )
          )}
      </div>

      {ready && step === STEP.REPORT && (
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 py-4 text-center">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => goToStep(STEP.SURVEY)}
            data-tracking-action-label="navigation"
            data-tracking-action-value="Zurück zum Fragebogen"
            data-tracking-action-position="bottom rescue"
          >
            <span className="mr-2 text-xs">
              <IconElement icon="arrowLeft" />
            </span>
            Zurück zum Fragebogen
          </button>
        </div>
      )}

      {ready && step === STEP.SURVEY && (
        <div className="max-w-screen-lg m-auto py-4 px-4 sm:px-6 lg:px-8 bg-white sticky bottom-0 z-20 border-t">
          <div className="flex items-center justify-end sm:justify-between">
            <div className="hidden sm:block">
              <span className="text-xs py-2 text-gray-800 font-medium">
                {Math.round(
                  (progress.answeredQuestions / progress.questions) * 100
                )}
                %
              </span>
              <div className="relative w-48 h-1 rounded-full bg-gray-200 overflow-hidden">
                <div
                  className="absolute inset-y-0 left-0 bg-gray-400 transition-width duration-300 ease-in-out"
                  style={{
                    width:
                      (progress.answeredQuestions / progress.questions) * 100 +
                      "%",
                  }}
                ></div>
              </div>
            </div>
            <button
              type="button"
              className={classNames({
                "flex items-center justify-center transition duration-400 inline-flex items-center md:px-6 md:py-3 md:text-base px-4 py-2 text-sm border border-transparent  font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-200": true,
                "cursor-pointer text-white bg-black hover:bg-black focus:ring-black":
                  progress.completed,
                "cursor-not-allowed text-gray-800  bg-gray-200": !progress.completed,
              })}
              onClick={() => progress.completed && goToStep(STEP.REPORT)}
              data-tracking-action-label="report"
              data-tracking-action-value={`zur Auswertung (${
                progress.completed ? "(completed)" : "(disabled)"
              })`}
              data-tracking-action-position="sticky footer"
            >
              <span>Zur Auswertung</span>
              <span className="ml-2 text-xs opacity-50">
                <IconElement icon="arrowRight" />
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlockAssessmentCx;
