import React, { Fragment } from "react";

export function CtaTitleElement({ title, isDark = false, color }) {
  const baseClassNames =
    "max-w-2xl text-3xl font-bold font-serif whitespace-pre-wrap sm:text-4xl sm:tracking-tight lg:text-5xl";
  // "text-3xl max-w-2xl leading-9 font-extrabold whitespace-pre-wrap tracking-tight sm:text-4xl sm:leading-10";

  const colorClassNames = isDark ? "text-white" : "text-gray-900";
  const classNames = [baseClassNames, colorClassNames].join(" ");

  return (
    <Fragment>
      {title && (
        <h2 className={classNames}>
          {title && typeof title === "string" && (
            <span className="block">{title}</span>
          )}
          {title &&
            Array.isArray(title) &&
            title.map((titleChunk, idx) => {
              const classNames =
                idx % 2 && !isDark ? `block ${color.text[600]}` : "block";
              return (
                <span className={classNames} key={idx}>
                  {titleChunk}
                </span>
              );
            })}
        </h2>
      )}
    </Fragment>
  );
}
